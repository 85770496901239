body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

main {
  padding-top: 60px; /* Ajoute un espace en haut pour ne pas que le contenu soit caché sous la barre */
}

.content {
  padding: 50px;
}

section h2 {
  margin-top: 0;
}