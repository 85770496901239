*,
*::before,
*::after {
    box-sizing: border-box; /* Inclure padding et bordure dans la largeur totale */
}

.contact-form {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px; /* Ajout d'un padding pour l'intérieur du formulaire */
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.contact-form h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.row {
    display: flex; /* Utiliser flexbox pour aligner les éléments */
    flex-wrap: wrap; /* Permettre au contenu de passer à la ligne */
}

.champ {
    margin: 10px; /* Réduire la marge pour éviter le débordement */
    flex: 1; /* Prendre un espace égal pour chaque champ */
}

label {
    display: block; /* Les labels prennent toute la largeur */
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

input[type="text"],
input[type="email"],
textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: calc(100% - 20px); /* Ajuster pour le padding et la bordure */
    transition: border-color 0.3s;
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
    border-color: #007BFF;
    outline: none;
}

textarea {
    height: 60px; /* Hauteur du textarea */
    resize: none; /* Empêche le redimensionnement */
}

button {
    padding: 10px 15px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px;
}

button:hover {
    background-color: #0056b3;
}

/* Media query pour écrans plus petits */
@media (max-width: 768px) {
    .row {
        flex-direction: column; /* Aligner les éléments en colonne */
    }
}
