body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .header-bg {
    background-color: #236299; /* Couleur de fond du bandeau */
    padding: 10px; /* Espace intérieur */
    position: fixed; /* Reste fixe en haut */
    top: 0; /* Positionne en haut de la page */
    left: 0; /* Aligné à gauche */
    right: 0; /* Aligné à droite */
    z-index: 1001; /* Assure qu'il est au-dessus de la barre de navigation */
    display: flex;
    justify-content: center; /* Centre le contenu horizontalement */
    align-items: center; /* Centre le contenu verticalement */
    text-align: center; /* Centre le texte à l'intérieur */
  }
  
  .header-bg.hidden {
    transform: translateY(-100%); /* Fait glisser le bandeau vers le haut */
    transition: transform 0.3s ease; /* Ajoute une transition pour l'animation */
  }
  
  .header-bg .credentitials {
    display: flex;
    align-items: center;
    margin: 0 20px; /* Espace à gauche et à droite */
    gap: 20px; /* Espace entre les éléments */
  }
  
  
  .header-bg p a {
    margin-left: 20px;
    margin-right: 20px;
  
  }
  
  
  
  
  nav {
    background-color: #ffffff;
    padding: 10px;
    position: fixed; /* Rend la barre fixe */
    top: 60px; /* Positionne la barre en dessous du bandeau */
    width: 100%; /* Ajuste la largeur à 100% */
    height: 60px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10002; /* La barre de navigation est en dessous du bandeau */
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: top 0.3s;
  }
  
  nav.hidden {
    top: 0; /* Remonte la barre de navigation */
  }
  
  .logo {
    display: flex;
    align-items: center;
  }
  
  .logo img {
    height: 40px;
    margin-right: 10px;
  }
  
  nav ul {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
  }
  
  nav ul li {
    position: relative; /* Pour le pseudo-élément */
    display: inline;
  }
  
  nav ul li a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    padding: 10px 20px;
    transition: color 0.3s; /* Transition pour la couleur */
  }
  
  nav ul li a:hover {
    color: #236299; /* Change la couleur au survol */
  }
  
  nav ul li::after {
    content: '';
    position: absolute;
    bottom: -5px; /* Positionne la barre en dessous du lien */
    left: 50%; /* Centré par rapport à l'élément parent */
    transform: translateX(-50%); /* Centrer l'élément */
    width: 0; /* Commence avec une largeur de 0 */
    height: 2px; /* Hauteur de la barre */
    background-color: #236299; /* Couleur de la barre */
    transition: width 0.3s; /* Transition pour l'animation */
  }
  
  nav ul li:hover::after {
    width: 100%; /* Élargit la barre au survol */
  }
  
  
  
  main {
    padding-top: 60px; /* Ajoute un espace en haut pour ne pas que le contenu soit caché sous la barre */
  }
  
  .content {
    padding: 50px;
  }
  
  section h2 {
    margin-top: 0;
  }
  
  #about .content, #emergency .content {
    display: flex; /* Utilisez Flexbox pour un alignement flexible */
    flex-direction: column; /* Aligne les éléments enfants en colonne */
    align-items: center; /* Centre horizontalement */
    justify-content: center; /* Centre verticalement */
    text-align: center; /* Centre le texte à l'intérieur des éléments */
    max-width: 900px;
    min-width: 400px;
    margin: 0 auto; /* Centre le conteneur dans son parent si le conteneur a une largeur définie */
  }
  
  #about h1, #emergency h1 {
    text-align: center;
    padding-top: 50px;
    font-size: 4.5em;
    color: #236299;
  }
  
  #about h2, #emergency h2 {
    text-align: center;
    padding-top: 50px;
    font-size: 1.5em;
    color: #236299;
  }
  
  #about p, #emergency p {
    font-size: 1.2em;
    text-align: center;
    color: #626262
  }
  
  @media (max-width: 768px) {
    #about h1, #emergency h1 {
      font-size: 3em;
    }
    
    #contact h1 {
      font-size: 2em;
    }
  
    #about h2 {
      font-size: 1em;
    }
    
    
    #about p, #emergency p {
      font-size: 1em;
    }
  
  
  }
  
  #emergency button {
    background-color: #2362999a;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
    width: 200px;
    height: 50px;
    border-radius: 5PX;
    font-size: 1rem;
  }
  
  #emergency button:hover {
    background-color: #236299;
  }
  
  .services-row {
    display: flex;
    flex-wrap: wrap; /* Permet aux éléments de passer à la ligne suivante */
    justify-content: center; /* Centre les éléments horizontalement */
    gap: 1%; /* Ajoute un espace entre les éléments */
    padding: 1%; /* Ajoute du padding autour des éléments */
  }
  
  .service {
    max-width: 500px; /* Limite la largeur des éléments */
    min-width: 300px; /* Fixe une largeur fixe pour les éléments */
    background-color: #36a2fb55;
    padding: 20px; /* Ajoute du padding à l'intérieur de chaque boîte */
    box-sizing: border-box; /* Assure que padding et bordures sont inclus dans la largeur totale */
    color: #fff; /* Change la couleur du texte pour mieux le voir sur fond sombre */
    margin: 10px; /* Ajoute des marges autour des éléments pour un espacement uniforme */
    display: flex;
    flex-direction: column; /* Aligne les éléments enfants en colonne */
    justify-content: space-between; /* Espace les éléments enfants à l'intérieur de chaque boîte */
    align-items: center; /* Centre les éléments enfants horizontalement */
    transition: transform 0.3s ease, background-color 0.3s ease, font-size 0.3s ease; /* Ajoute une transition douce pour la transformation */
    text-align: center; /* Centre le texte horizontalement */
  }
  
  .service button {
    background-color: #ffffff35;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
  }
  
  .service button:hover {
    background-color: #ffffff50;
  }
  
  .service:hover {
    background-color: #36a2fb7f; /* Change la couleur de fond lors du survol */
    transform: scale(1.02); /* Agrandit l'élément à 110% */
  }
  
  #services .parallax {
    background-image: url('../../assets/background/section-services.webp');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
  }
  
  #services h1 {
    padding-top: 50px;
    color: white;
  }
  
  #services .title {
    font-size: 3em;
    text-align: center;
  }
  
  #services p {
    font-size: 1.2em;
    text-align: center;
  }
  
  
  #team .parallax {
    background-image: url('../../assets/background/section-notre-equipe.jpg');
    height: 50vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  #contact h1 {
    text-align: center;
    padding-top: 50px;
    font-size: 4.5em;
    color: #236299;
  }
  
  #contact h3 {
    font-size: 1.8em;
    text-align: center;
    color: #626262
  }
  
  #contact p {
    font-size: 1em;
    text-align: center;
    color: #626262
  }
  
  #contact button {
    background-color: #9f9f9f;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
  }
  
  #contact button:hover {
    background-color: #626262;
  }
  
  #contact .separator {
    width: 1px;
    height: auto; /* Laissez auto pour qu'il s'adapte à la hauteur du parent */
    background-color: #E1E1E1;
    margin: 0 20px; /* Pour espacer le séparateur des contacts */
    align-self: stretch; /* Si vous utilisez flexbox pour étendre la hauteur */
  }
  
  #contact .contacts {
    display: flex; /* Pour aligner les contacts côte à côte */
    justify-content: space-around; /* Pour espacer les éléments */
    margin-top: 20px; /* Pour espacer du contenu au-dessus */
  }
  
  #contact .contact {
    flex: 1; /* Pour que chaque contact prenne une largeur égale */
    padding: 10px; /* Espacement interne */
    text-align: center; /* Centrer le texte */
  }
  
  @import url('https://unpkg.com/leaflet/dist/leaflet.css');
  
  #contact .parallax {
    background-image: url('../../assets/background/section-notre-equipe.jpg');
    height: 50vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  #team .statistic {
    display: flex;
  }
  
  #team .statistic .data {
    width: 33.3333%;
    background-color: #3667fbde;
    padding: 20px;
    box-sizing: border-box;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease, background-color 0.3s ease, font-size 0.3s ease;
    text-align: center;
  }
  
  #team .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 900px;
    min-width: 400px;
    margin: 0 auto;
  }
  
  #team img {
    width: 30%;
    height: 30%;
    border-radius: 5px;
  }
  
  #team .content h2{
    padding-top: 30px;
    color: #236299;
  }
  
  #team .content p{
    color: #626262;
  }
  
  @media (max-width: 768px) {
    #team .statistic {
      flex-direction: column;
    }
    #team .statistic .data {
      width: 100%;
    }
  }